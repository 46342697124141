<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header class="ion-margin-bottom">
      <h3>Assigned students</h3>
    </ion-list-header>
    <ion-item v-for="item in resources.data" :key="item.id">
      <ion-label>
        <h2>
          {{ item.user.user_detail.name }}
        </h2>
        <ion-note
          >Section: {{ item.section_standard.section.name }}, Roll:
          {{ item.roll_no }}</ion-note
        >
      </ion-label>
      <ion-note v-if="isMandatory" slot="end">Mandatory</ion-note>
      <ion-note v-else slot="end"> {{ SubjectStudentStatus(item) }} </ion-note>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import { IonList, IonListHeader, IonLabel, IonItem, IonNote } from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    subjectId: {
      required: true,
    },
    loadedSubject: {
      required: true,
    },
  },

  emits: ["refresh"],

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonNote,
  },
  data() {
    return {
      updated: false,
    };
  },

  computed: {
    isMandatory() {
      const subject = this.loadedSubject;
      const mandatory = subject.is_mandatory == true ? true : false;
      return mandatory;
    },
  },

  methods: {
    SubjectStudentStatus(item) {
      const subjects = item.subjects;
      let subjectStudentStatus = "Unassigned";

      if (Array.isArray(subjects) && subjects.length > 0) {
        subjectStudentStatus =
          subjects[0].subject_id == this.subjectId ? "Assigned" : "Unassigned";
      }
      return subjectStudentStatus;
    },
  },

  beforeUpdate() {
    this.updated = false;
  },

  updated() {
    this.updated = true;
  },
};
</script>